var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"uiu2_zh-7xHKNfxA2LwqY"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { BrowserOptions } from '@sentry/browser/types/client';

const SENTRY_OPTIONS = process.env.NEXT_PUBLIC_SENTRY_OPTIONS;
const sentryOptions: BrowserOptions = SENTRY_OPTIONS ? JSON.parse(SENTRY_OPTIONS) : {
  dsn: "https://a6d3bfc82f64475589131efffc82ea79@sentry.smart-gates.net/4",
  environment: 'local',
  tracesSampleRate: 1.0,
};

sentryOptions.tracesSampler = (samplingContext) => {
  // Пока не отправляем информацию о загрузке страницы
  if (samplingContext.transactionContext.op === 'pageload') {
    return 0;
  }

  return 1;
};

Sentry.init(sentryOptions);
